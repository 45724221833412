<template>
  <div>
      <div class="modal"  @click.self="changeMordal2()">
        <div class="modal-content">
           <div class="modal-close"><button @click="changeMordal2()"><img src="@/assets/images/close.svg"></button></div>
                <h1>カテゴリ編集</h1>
                <p>ID:{{formatId(id)}}</p>
                <p>カテゴリ名：<input type="text" v-model="nCateName" class="input"></p>
                <p><button class="btn btn-m blue" @click="updateCategory()">更新</button></p>
        </div>
    </div>

   
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from "vuex"
import commonData from '@/assets/js/commonData'
export default {
  mixins: [commonData],
  name: 'newCate',
  components: {

  },
  props:[
      'id',
  ],
  data(){
    return{
      nCateName:'',
      c_status:'',

    }
  },
  methods:{
   changeMordal2(){
    this.$emit('cate-click2',0) ;
   },
    updateCategory(){
       let params = new URLSearchParams();
            params.append('admin', "update_cate");
            params.append('id', this.id);
            params.append('name',this.nCateName);
        this.axios.post(this.api,params
      )
      .then(()=>{
          this.changeMordal2();
      })
      .catch((e)=>{
        console.log(e);
      })
    },
     getCategoryDetail(id){
        this.axios.get(this.api,{
          params: {
          g: "category-detail",
          id: id,
      }

      })
      .then((response)=>{
        console.log(response.data)
        this.nCateName = response.data.c_name
        // console.log(response.data)
        this.changeMordal();
         
      })
      .catch((e)=>{
        console.log(e);
      })
    }

  },
  watch:{
    categories(value){
        this.$store.commit('changeCategories',value)
    }
    
  },
  computed:{
    ...mapGetters([
       'categories'
      ]),
  },
  mounted(){
    this.getCategoryDetail(this.id)
  }

}
</script>
<style scoped lang="scss">
@import "@/scss/common.scss";
h1{
  color: rgb(0,0,0);
  margin-bottom: 2em;

}
input{
  text-align: left;
}
p{
  text-align: center;
  margin: 15px auto;
  button{
  margin: 50px auto 15px auto;
}
}




</style>



