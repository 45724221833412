<template>
<div>

  
      
  <div class="login">
    <p class="pic">
    <img src="@/assets/images/logo_head_left.svg"><br><br>
    <img src="@/assets/images/logo_head_right.svg">
    </p>
       
     
      <h1>LOGIN</h1>
      <form>
     <label><input type="text" v-model="email" placeholder="EMAIL" class="input"></label>
      <label><input type="password" v-model="pwd" placeholder="PASSWORD" class="input"></label>
      <p class="login-btn"><router-link to="/" class="btn-l orange">トップ</router-link><button class="btn btn-l orange" @click="login()" type="button">ログイン</button></p>
       </form>
      </div>
       
</div>
</template>

<script>
// @ is an alias to /src

import commonData from '@/assets/js/commonData'
export default {
    mixins: [commonData],
  name: 'Login',
  data(){
    return{
      email:'',
      pwd:'',
    }
  },
  methods:{
    through(){
      const origin = this.$store.state.origin
      const token = this.$store.state.token
      if(origin == token){
        this.$router.push('/List')
      }


    },
    login(){
      let params = new FormData();
            params.append('send', "pwd")
            params.append('email', this.email)
            params.append('pwd', this.pwd)
        this.axios.post(this.api + "login.php" ,params
      )
      .then((response)=>{
          // console.log(response.data)
          if(response.data){
              this.$store.commit('changeToken',response.data.token);
              this.$store.commit('changeName',response.data.name);
              this.$router.push('/List')

          }else{
            alert('認証エラー');
          }
         // this.$router.push('/List')
      })
      .catch((e)=>{
        console.log(e);
        
      })
    }
  },
  created(){
    this.through()
  }
  
}
</script>
<style scoped lang="scss">
@import "@/scss/common.scss";
img{
  width: 100%;
}
label{
  display: block;
  margin: 30px 0;
}
h1{
  margin-bottom: 15px;
}
.pic{
  margin-bottom: 20px;
}
.login{
    width: 338px;
    height: 330px;
    margin: 150px auto;
}


.input{
  font-family:$fa_number ;
  background-color: rgb(255,255,255);
  width: 100%;
  margin: 0;
  padding:10px 15px;

  &:focus{
    background-color: rgb(249, 255, 197);
  }
 
  
}


.login-btn{
  margin-top: 50px;
  display: flex;
  justify-content: space-around;

}

</style>
