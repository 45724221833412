<template>
  <div>
      <div class="modal"  @click.self="changeMordal()">
        <div class="modal-content">
           <div class="modal-close"><button @click="changeMordal()"><img src="@/assets/images/close.svg"></button></div>
                <h1>新規カテゴリ作成</h1>
                <p>カテゴリ名：<input type="text" v-model="nCateName" class="input"></p>
                <p><button class="btn btn-m blue" @click="createCategory()">登録</button></p>
           </div>
       
    </div>

   
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from "vuex"
import commonData from '@/assets/js/commonData'
export default {
  mixins: [commonData],
  name: 'newCate',
  components: {

  },
  data(){
    return{
      nCateName:''
    }
  },
  methods:{
   changeMordal(){
    this.$emit('cate-click',0) ;
   },
    createCategory(){
       let params = new URLSearchParams();
            params.append('admin', "create_cate");
            params.append('name',this.nCateName);
        this.axios.post(this.api,params
      )
      .then(()=>{
          this.changeMordal();
      })
      .catch((e)=>{
        console.log(e);
      })
    }

  },
  watch:{
    categories(value){
        this.$store.commit('changeCategories',value)
    }
    
  },
  computed:{
    ...mapGetters([
       'categories'
      ]),
  },
  mounted(){
    this.getCategory()
  }

}
</script>
<style scoped lang="scss">
@import "@/scss/common.scss";
h1{
  color: rgb(0,0,0);
  margin-bottom: 2em;

}
input{
  text-align: left;
}
p{
  text-align: center;
  margin: 15px auto;
  button{
  margin: 50px auto 15px auto;
}
}




</style>



