<template>
  <div>
    <Header></Header>

    <Datalists
      class="datalists"
      :usertype="usertype"
      @downloading="dl=$event"
    >
    </Datalists>
     <footer class="footer">ROTARY INTERNATIONAL DISTRICT2790
       <!-- <a href="https://www.rid2790.jp/" target="_blank"></a>
      <div class="dijitalpocket-archhive">
        <img src="@/assets/images/digipock.svg" />
      </div> -->
     </footer>

   <template v-if="dl==1">
       <Animation :tx="tx"></Animation>
   </template>


  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header'
import Datalists from '@/components/Datalists'
import Animation from '@/components/Animation'

import { mapGetters } from "vuex"
import commonData from '@/assets/js/commonData'
export default {
  mixins: [commonData],
  name: 'Archives',
  components: {
   Datalists,
   Header,
   Animation

  },
  data(){
    return{
      usertype:"0",
      dl:0,
      tx:"NOW DOWNLOADING"
    }
  },
  methods:{
  firstDisplay(){
      const jsDysplay = document.getElementById('jsDysplay');
      jsDysplay.click();
    }
  },
   watch:{

  },
  computed:{
    ...mapGetters([
      //  'getSearch',
      //  'getPrimary',
      //  'search',
      //  'primary'
      ]),
      //  getLists(){
      //  let end = this.currentPage * this.pageRange;
      //  let start = (this.currentPage * this.pageRange)-this.pageRange;
      //  return this.lists.slice(start, end);

      // },
  },
  mounted(){
     this.firstDisplay();
  },


}
</script>
<style scoped lang="scss">
@import "@/scss/common.scss";

p{
  text-align: center;
  color: rgb(255,255,255);
  font-size: 30px;
}
</style>



