<template>
  <div class="loader">
   <div class="download">
        <span></span>
        <span></span>
        <span></span>
        <p>{{tx}}</p>
    </div>
  
  </div>
</template>

<script>
export default {
  props:['tx'],
 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/scss/common.scss";
.loader{
  position:fixed;
  width: 100vw;
  height: 100vh;
  top:0;
  left: 0;
  z-index: 10000;
  background-color:rgba(255,255,255,.98);
}
.download{
    position: fixed;
    left: 50%;
    top: 40%;
    margin-left: -50px;
    margin-top: -10px;
    text-align: center;
    font-family:'Montserrat', sans-serif;
    font-weight: bold;
    color: rgb(100,100,100);
}
p{
  width: 300px;
  text-align: center;
    margin-top: 30px;
    font-size: 16px;
    text-align: center;
    margin-left: -90px;
  }

.download span{
  display: block;
  background: rgb(100,100,100);
  width: 100px;
  height: 5px;
  margin-top: 15px;
  animation: load 2s infinite;
  border-radius: 5px;
}

.download span:nth-child(2){animation-delay: 100ms;}
.download span:nth-child(3){animation-delay: 200ms;}

@keyframes load{
  50%{
    width: 5px;
    margin-right: 95px;
    opacity: .1;
  }
}

</style>
