<script>
import commonData from '@/assets/js/commonData'
export default {
    mixins: [commonData],
  data(){
    return{
      chk:''
    }
  },
  methods:{
    test(){
      const token = this.$store.state.token
      const origin = this.$store.state.origin
      if(token == ""){
          this.$router.push('/Login')
          
      }else if(token !== origin){
           this.$router.push('/Login')
      }
    }
  },
  watch:{
   
    
  },
  mounted(){
    this.test()
  }


}
</script>
