<template>
  <div>
    <div class="content list-width">
        <h1>
          <template v-if="usertype==0">
            アーカイブ一覧
          </template>
          <template v-else>
            データ一覧
          </template>
          
          </h1>
        <Search @change-display="displayLists()" :usertype="usertype"></Search>
        <div class="pager">
            <select class="sort" v-model="sort" @change="orderChange()" >
              <!-- <option value="id" >並び替え:id</option> -->
              <option value="type">並び替え:データ形式</option>
              <option value="cate">並び替え:カテゴリ</option>
              <option value="new">並び替え:新しい順</option>
              <option value="old">並び替え:古い順</option>
            </select>

          <VuejsPaginate
            v-model="currentPage"
            :page-count="pagerCount"
            :page-range="pageRange"
            :margin-pages="5"
            :click-handler="clickCallback"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination'"
            :page-class="'page-item'"
            hide-prev-next="true"
            >
          </VuejsPaginate>
        </div>
    
        <div class="doc-list">
          <table>
            <thead>
              <tr>
                <th class="cbox">
                  <template v-if="usertype==0">
                    <label ><input type="checkbox" v-model="allchk" @change="allchkAction()"></label>
                  </template>
                  </th>
                <!-- <th class="idn">id</th> -->
                <th class="year">年度</th>
                <th class="title">タイトル</th>
                <th class="cate">カテゴリ</th>
                <th class="update">登録日</th>
                <th class="msg">コメント</th>
                <th class="type">形式</th>
                <th class="size">サイズ<small>(MB)</small></th>
                <th class="dlbtn"></th>
              </tr>
            </thead>
            <tbody>
              <tr class="list-content" v-for="list in getLists" :key="list">
                  <td class="cbox">
                    <template v-if="usertype==0">
                      <label><input type="checkbox" :value="list.id" v-model="chk" class="dlchk"></label>
                    </template>
                  </td>
                  <!-- <td class="idn">{{formatId(list.id)}}</td> -->
                  <td class="year">{{formatDsiplayYea(list.year)}}</td>
                  <td class="title">{{list.title}}</td>
                  <td class="cate">{{categoryName(list.cate)}}</td>
                  <td class="update">{{formatDsiplayTime(list.register_at)[0]}}
                    <small>{{formatDsiplayTime(list.register_at)[1]}}</small>
                  </td>
                  <td class="msg">{{list.memo}}</td>
                  <td class="type">{{list.type}}</td>
                  <td class="size">{{list.size}}</td>
                  <td class="dlbtn">
                    <template v-if="usertype==='0'">
                        <button type="button" class="btn orange btn-dl"  @click="sdown(list.id)">DL</button>
                    </template>
                    <template v-else>
                       <p class="delete-detail">
                            <button class="btn orange"  @click="sDetail(list.id)">詳細</button>
                            <button class="btn blue"  @click="sDelete(list.id)">削除</button>
                        </p>
                    </template>
                  
                    </td>
                </tr>
            </tbody>
          </table>
        </div>
        <template v-if="usertype==0">
            <div class="multi_btn">
          <form :action="this.api + 'zip.php'" method="post" >
            <input type="hidden" name="ids" :value="chk" >
            <input type="hidden" name="dl" value="multi" >
            <input type="submit" value="まとめてダウンロード" class="submit" :disabled="disabledSubmit()" :class="chkdisable()" formtarget="_blank"> 
          </form>

          <!-- <button class="btn-m orange" @click="multiDl()">まとめてダウンロード</button> -->
         
         
         </div>
          
        </template>


      
        
    </div>

   
  </div>
</template>

<script>
// @ is an alias to /src
import Search from '@/components/Search'

import VuejsPaginate from 'vuejs-paginate'
import { mapGetters } from "vuex"
import commonData from '@/assets/js/commonData'
import listMthods from '@/assets/js/listMthods'
import downloadMthods from '@/assets/js/downloadMthods'
import category from '@/assets/js/category'
export default {
  mixins: [commonData,listMthods,downloadMthods,category],
  name: 'Datalists',
  components: {
    Search,
    VuejsPaginate,
  
  },
  props:['usertype'],
  data(){
    return{
      totalDataSize:0,
      limitsize :150,
      updateid:0,
   
    }
  },
  methods:{
 sDetail(id){
    this.$emit('click-detail',id)
 },
  sDelete(id){
     if(confirm(`${id}を削除してよろしいですか`)){
       
         let params = new URLSearchParams();
            params.append('admin', "delete_doc");
            params.append('id', id);
            this.axios.post(this.api,params
      )
      .then(()=>{
            this.displayLists()
           alert('削除しました')
      })
      .catch((e)=>{
        console.log(e);
      })
     }    
    },
    formatDsiplayYea(n){
       const simo = String(n).slice(4, 6);
       const kami = Math.floor(Number(n) / 100)
       return String(kami) + '-' + simo
    },
    formatDsiplayTime(t){
       const time =t.replace(/-/g,'/')
       const timmArr =time.split(' ')
       return  timmArr

    },
    chkdisable(){
      if(this.chk.length == 0  || this.totalDataSize > this.limitsize  ){
        return 'disabled-btn'
      }
    },
    totalsize(){
      let tsize = 0
      for(let i = 0 ;i < this.chk.length ; i++){
          const s = this.getLists.find((v)=>v.id==this.chk[i]).size
          tsize += s
      }
        this.totalDataSize = tsize
   
    },
    disabledSubmit(){
       if(this.chk.length==0 || this.totalDataSize > this.limitsize ){
          return true
       }
    }

 
  },
   watch:{
     chk(){
       this.totalsize()
       if(this.totalDataSize > this.limitsize){
          alert("まとめてダウンロードは150MB以下の対応となります。")
       }
     }
  },
  computed:{
    ...mapGetters([
       'getSearch',
       'getPrimary',
       'search',
       'primary',
      ]),
       getLists(){
       let end = this.currentPage * this.pageRange;
       let start = (this.currentPage * this.pageRange)-this.pageRange;
       return this.lists.slice(start, end);

      },
  },
  mounted(){
  },


}
</script>
<style scoped lang="scss">
@import "@/scss/common.scss";

  .submit{
    font-size: 1.2em;
    padding: 10px 25px;
    border-radius: 5px;
    background-color: $orange;
    text-shadow: 0px 0 1px rgb(0,0,0);
    color: rgb(255,255,255);
    transition: .2s;
    cursor: pointer;
    &:hover{
      background: darken($orange, 15%);
    }
  }
.multi_btn{
.disabled-btn{
  background-color: rgb(73, 73, 168);
  color: rgb(111, 136, 204);
  &:hover{
   opacity: 1;
   box-shadow: none;
  }
}
}
table{
  width: calc(100% - 30px);
  margin: 0 auto;
  table-layout: fixed;
  thead{
    border-bottom: 2px solid rgb(0,0,0);
    th{
      font-weight: 400;
      padding: 5px 5px;
      text-align: center;
    }
  }
  tbody{
    td{
      padding: 6px 5px;
      vertical-align: middle; 
    }
    tr:first-of-type td{
      padding-top:10px;
    }
    tr{
      border-bottom: 1px solid rgb(197, 197, 228);
      &:last-of-type{
         border-bottom: 1px solid rgb(0,0,0);
      }
    }
  }


}
.btn-dl{
  padding: 2px 15px;
  margin-top:-1px;
  color: rgb(255,255,255);
  text-shadow: 0 0 2px rgb(0,0,0);
  border-radius: 3px;
  transition: .2s;
  &:hover{
    background: lighten($orange,10%);
  }
}

.size{
  text-align: center;
  small{
    display: block;
     text-align: center;
  }
}
.cbox{
  text-align: center;
  width: 20px;
}
.idn{
  text-align: center;
  width: 40px;
}
.year{
  text-align: center;
  width: 80px;
}
.title{
  width: 350px;
}
.cate{
  text-align: center;
  width: 90px;
}
.update{
  text-align: center;
  width: 85px;
}
.msg{
  width: 120px;
  font-size: .9em;
}
.type{
  text-align: center;
  width: 55px;
}
.size{
  width: 55px;
  font-size: .9em;
}
.dlbtn{
  text-align: center;
}
tbody{
  td:nth-of-type(even){
    background: rgba(197, 197, 228,.2);
  }
  td:nth-of-type(odd){
    background: rgba(197, 197, 228,.1);
  }
}
.delete-detail{
  button{
    font-size: .9em;
    display: block;
    padding: 2px 6px;
  color:rgb(255,255,255);
  text-shadow:0 0 1px rgb(0,0,0);
  margin: 2px auto;
  :last-of-type{
    margin-bottom: 0;
  }

  }
  
}


</style>



