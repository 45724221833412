import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    search:{
      keyword:'',
      sYear:'',
      eYear:'',
      cate:'0'
    },
    primary:"id",
    categories:[],
    token:'',
    name:'',
    origin:'i24cqvvqqu'
  },
  getters:{
    getSearch: state => {
      return state.search;
    },
    getPrimary:state=>{
      return state.primary
    },
    getCategories:state=>{
      return state.categories
    }
  },
  mutations: {
    changeSearch(state,value){
      state.search = value
    },
    changePrimary(state,value){
      state.primary = value
    },
    changeCategories(state,value){
      state.categories = value
    },
    changeToken(state,value){
      state.token = value
    },
    changeName(state,value){
      state.name = value
    },
  },
  actions: {
  },
  modules: {
  }
})
