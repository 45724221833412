

export default {
    data(){
      return {
        loginstep: 0,
        //api: "http://192.168.33.10/rotary2790_histories/api/",
        api: "./api/",
      };
    },
    methods:{
        formatId(id){
            const fid = ('0000'+id).slice(-4)
            return fid
          },
    },
    watch:{


  }
}
