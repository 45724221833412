import { mapGetters } from "vuex"
export default {
    data(){
        return{
          categories:[],
      }
    },
    methods:{
      getCategory(){
        this.axios.get(this.api,{
          params: {
          g: "category",
      }

      })
      .then((response)=>{
        this.categories = response.data
        // (response.data)
         
      })
      .catch((e)=>{
        (e);
      })
    }
  
    },
    computed:{
      ...mapGetters([
        'categories'
        ]),
    },
    watch:{
   
      categories(value){
          this.$store.commit('changeCategories',value)
      }

    },
    mounted(){
      this.getCategory()
    
    }
}