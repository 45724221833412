export default {
    data(){
        return {
          currentPage: 1,
          pagerCount: 0,
          pageRange: 25,
          search: [],
          primary: "null",
          sort: "new",
          chk: [],
          allchk: 0,
          lists: [],
          dl: 0,
          categories: [],
        };
    },
    methods:{
      displayLists(){
        this.getlists(this.search.keyword, this.search.sYear, this.search.eYear, this.search.cate);
       const targetCss = document.querySelectorAll(".page-item");
       targetCss.forEach(function(v) {
         if (v.children[0].textContent == 1) {
           v.children[0].click();
         }
       });
      
       
      },
      categoryName(id) {
        const cate = this.categories
       
        const cateName = cate.find((v) => {
          return v.c_id == id;
        });
          if(cateName){
                return cateName.c_name
          } else {
            return '未設定';
          }
      },
      allchkAction(){
         const dlchk = document.querySelectorAll('.dlchk')
         this.chk = []
        if(this.allchk){
          for(let i = 0 ;i < dlchk.length ; i++ ){
              dlchk[i].checked = true;
            this.chk[i] =  dlchk[i].value
          }
         
        }else{
           dlchk.forEach((v)=>{
              v.checked = false;
           })
        }
      },
        getlists(key,syear,eyear,cate){
          this.axios.get(this.api,{
            params: {
            g: "list",
            key:key,
            syear:syear,
            eyear:eyear,
            cate:cate
        }
  
        })
        .then((response)=>{
          // console.log(response.data)
            this.lists = []
            const li = response.data
                li.sort((a,b)=>{
                  if(a.register_at > b.register_at) return -1 
                  if(a.id < b.id) return 1 
                  // if(a.id > b.id) return -1 
                  // if(a.id< b.id) return 1 
                  return 0
                })
            this.lists = li
            this.pagerCount = Math.ceil(this.lists.length / this.pageRange)
        })
        .catch((e)=>{
          console.log(e);
        })
      },
      orderChange() {
        if(this.sort == "type"){
            this.lists.sort((a,b)=>{
            if (a["type"] > b["type"]) return -1; 
            if(a.type< b.type) return 1 
            return 0
            })
        }else if(this.sort == "cate"){
            this.lists.sort((a,b)=>{
              if (a["cate"] > b["cate"]) return -1; 
              if(a.cate< b.cate) return 1 
              return 0
              })
        }else if(this.sort == "new"){
            this.lists.sort((a,b)=>{
              if (a["register_at"] > b["register_at"]) return -1; 
              if(a.register_at< b.register_at) return 1 
              return 0
              })
        }else if(this.sort == "old"){
            this.lists.sort((a,b)=>{
              if (a["register_at"] > b["register_at"]) return 1; 
              if (a["register_at"] < b["register_at"]) return -1; 
              return 0
              })
        }
         const targetCss = document.querySelectorAll(".page-item");
         targetCss.forEach(function(v) {
           if (v.children[0].textContent == 1) {
             v.children[0].click();
           }
         });
      },
  
    },
    watch:{
      getSearch(value){
        this.search = value
      },
      getPrimary(value){
        this.primary = value
      },
      search(value){
        this.$store.commit('changeSearch',value)
      },
      primary(value){
        this.$store.commit('changePrimary',value)
      },
      currentPage(){
         this.chk = []
         this.allchk = false
      },

    },
    mounted(){
      this.search = this.$store.state.search
      this.primary = this.$store.state.primary
      this.categories = this.$store.state.categories
    }
}