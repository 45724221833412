<template>
  <div id="admin">
    <LoginChk></LoginChk>
    <Header></Header>
    <div class="content">
        <h1>カテゴリ一覧</h1>
        <div class="category-list">
          <div class="new-category"> <router-link to="/" class="btn-s orange">トップ</router-link><button class="btn orange btn-s"  @click="cateModal=2">新規</button><router-link to="/List" class="btn-s orange">一覧</router-link> </div>
          <table>
            <thead>
            <tr>
              <th>
                  id
              </th>
              <th>
                  カテゴリ名
              </th>
               <th>
                  
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="Category in Categories" :key="Category">
              <td>
                  {{formatId(Category.c_id)}}
              </td>
              <td>
                  {{Category.c_name}}
              </td>
               <td class="btn-td">
                 <button class="btn orange btn-s"  @click="detail(Category.c_id)">詳細</button>
                  <button class="btn blue btn-s"  @click="delete_cate(Category.c_id)">削除</button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

    </div>
      <template v-if="cateModal==2">
          <NewCate @cate-click="modalchg()"></NewCate>
          
      </template>
      <template v-else-if="cateModal==1" >
          <UPDATECate :id = "currentCateId" @cate-click2="modalchg2()"></UPDATECate>
      </template>  
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header'
import NewCate from '@/components/createCate'
import UPDATECate from '@/components/updateCate'
import LoginChk from '@/components/LoginChk'
import { mapGetters } from "vuex"
import commonData from '@/assets/js/commonData'
export default {
  mixins: [commonData],
  name: 'Category',
  components: {
    Header,
    NewCate,
    UPDATECate,
    LoginChk
  },
  data(){
    return{
      cateModal :0,
      Categories:[],
      currentCateId:''
    }
  },
  methods:{
     modalchg(){
       this.getACategory()
       this.cateModal = 0
     },
     modalchg2(){
       this.getACategory()
       this.cateModal = 0
      
     },
      getACategory(){
        this.axios.get(this.api,{
          params: {
          g: "category",
      }

      })
      .then((response)=>{
        this.Categories = response.data
        //  console.log(response.data)
         
      })
      .catch((e)=>{
        console.log(e);
      })
    },
    detail(id){
      this.currentCateId = id
      this.cateModal = 1
      
    },
    delete_cate(id){
      if(confirm(`本当に削除してよろしいですか？`)){
          let params = new URLSearchParams();
            params.append('admin', "delete_cate");
            params.append('id', id);
          this.axios.post(this.api,params
          )
          .then(()=>{
          alert(`アーカイブの各該当カテゴリは「未設定」に変更しました。`);
          this.getACategory()
          })
          .catch((e)=>{
        console.log(e);
        })
        }
    }

    
  },
   watch:{
      Categories(value){
          this.$store.commit('changeCategories',value)
      },
      getCategories(value){
        this.Categories = value
      }
    
  },
  computed:{
    ...mapGetters([
       'getCategories',
       'categories'
      ]),

  },
  mounted(){
      this.getACategory()
  },


}
</script>
<style scoped lang="scss">
@import "@/scss/common.scss";
.new-category{
  right: 0;
  .btn-s{
  margin: 0 0 0 5px;
}

}


</style>



