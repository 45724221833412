<template>
<div>
  <router-link to="/Login" class="adminbtn">管理者 </router-link>
  <div class="home">
    <p><router-link to="/Archives" class="topimg">
      <img src="@/assets/images/logo_top.svg">
      </router-link>
    </p>
    <p class="top">
      <router-link to="/Archives" class="topbtn">ファイル検索はこちら</router-link>
    </p>
  </div>
<!-- <div class="dijitalpocket-top">
    <img src="@/assets/images/digipock.svg" />
</div> -->

</div>

</template>

<script>
// @ is an alias to /src


export default {
  name: 'Home',

}
</script>
<style scoped lang="scss">
@import "@/scss/common.scss";
.top{
 margin: 40px 0 0 0;
 padding: 10px;
}
.topbtn{
  background: $orange;
  width: 100%;
  display: block;
  text-align: center;
  color: rgb(255,255,255);
  padding: 15px 0;
  font-size: 1.2rem;
  border-radius: 6px;
  transition: .2s;
  box-shadow: 0px 0px 3px  darken($orange, 60%);
  &:hover{
    background: darken($orange, 10%);
  }
}
.topimg{
  img{
    transition: .2s;
    &:hover{
      opacity: .7;
    }
  }
}
.adminbtn{
  display: block;
  position: fixed;
  top:20px;
  left: 20px;
  font-size: 1.2em;
  padding: 5px 30px;
  background: darken($blue, 15%);
  color: lighten($blue, 30%);
  transition: .2s;
  &:hover{
    background:lighten($blue, 50%);
    color:  darken($blue, 30%);
  }
}

</style>
