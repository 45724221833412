<template>
  <div>
        <div class="search-box">
            <div class="top-linkbtn">
                <template v-if="usertype!=='0'">
                 <router-link to="/Register" class="btn-m orange">新規</router-link>
                   <router-link to="/Category" class="btn-m orange">カテゴリ</router-link>
                </template>

                  <router-link to="/" class="btn-m orange">トップ</router-link>
            </div>
            <div class="row1">
                  <p>
                      <label class="note-parent">キーワード<input type="text" class="input keyword" v-model="search.keyword" placeholder="複数可">
                      <div class="note-child">
                        ※ 複数の場合は「,」（カンマ）で区切ってください。
                        </div>
                        </label>
                  </p>
                  <p>
                    年度
                     <select class="select year" v-model="search.sYear">
                         <option v-for="y in years" :key="y" :value="formatYear(y)">{{y}}</option>
                      </select>
                      -
                     <select class="select year" v-model="search.eYear">
                        <option v-for="y in years" :key="y" :value="formatYear(y)">{{y}}</option>
                     </select>
                  </p>
                   <p>
                    カテゴリ
                    <select class="select category" v-model="search.cate">
                       <option value="0">全て</option>
                      <option v-for ="category in categories" :key="category.c_id" :value="category.c_id">{{category.c_name}}</option>
                    </select>
                  </p>
                   <p class="submit">
              <button class="btn-m blue " id="jsDysplay" @click="$emit('change-display')">表示</button>
            </p>

            </div>

    </div>


  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from "vuex"
import commonData from '@/assets/js/commonData'
import year from '@/assets/js/year'
import category from '@/assets/js/category'
export default {
  mixins: [commonData,year,category],
  name: 'Search',
  props: ['usertype'],
  components: {

  },
  data(){
    return{
      search:{
        keyword:'',
        sYear:'',
        eYear:'',
        cate:'',
      }
    }
  },
  methods:{



  },
  watch:{
    getSearch(value){
      this.search = value
    },
    getPrimary(value){
      this.primary = value
    },
       search(value){
        this.$store.commit('changeSearch',value)
      },

  },
  computed:{
    ...mapGetters([
       'getSearch',
       'getPrimary',
       'search',
       'primary',

      ]),
  },
  mounted(){
    //this.$store.state.search.sYear=this.formatYear(this.years[0]);
    this.$store.state.search.sYear=this.formatYear('1949-50');
    this.$store.state.search.eYear=this.formatYear(this.years[0]);
    this.search = this.$store.state.search;


  }

}
</script>
<style scoped lang="scss">
@import "@/scss/common.scss";

.search-box{
    background-color: rgb(255,255,255);
    margin: 15px auto 0 auto;
    border-radius: 6px;
    padding: 15px 0 5px 15px;
    position: relative;
    .keyword{
        width: 15em;
    }
    .row1{
        display: flex;
        p{
        margin-right: 2em;
        }
        margin-bottom: 1em;
    }

}
 .submit{
            margin-left: auto;
            .btn-m{
                padding: .5em 0em;
                // width: 10em;
            }
        }
  .note{
    &-parent{
      position: relative;
    }
    &-child{
      position:absolute;
      top:28px;
      font-size: .8em;
      color:red;
      right: 0;
    }
  }

</style>



