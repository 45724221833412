
export default {
    data(){
      return{
        
      }
    },
    methods:{
      sdown(id){
        const url = this.api + 'download.php'
       //  window.open(url);
      //  console.log(url)
          this.axios.get(url,{
             params: {
             type: "single",
             id:id,
         },
         })
         .then((response)=>{
          //  console.log(response.data);
            let fileURL =  response.data['path'] + "file.php?name=";
                fileURL += response.data['title'] + "&file=" +  response.data['filename'] ;

             const fileLink = document.createElement('a');
             fileLink.href =  fileURL;
             fileLink.setAttribute('download', response.data['title']);
             //fileLink.appendChild(document.createTextNode('test'));
             document.body.appendChild(fileLink);
             fileLink.click();
             
         })
         .catch((e)=>{
           console.log(e);
         })
       },
       multiDl(){
        this.$emit("downloading", 0);

        const url = this.api + 'download.php'
        this.axios.get(url,{
           params: {
           type: "multi",
           ids:this.chk,
       },
       })
       .then((response)=>{
          // console.log(response.data);
            const fileURL = response.data['path'];
           const fileLink = document.createElement('a');
           fileLink.href =  fileURL;
           fileLink.setAttribute('download', response.data['name']);
           //fileLink.appendChild(document.createTextNode('test'));
           document.body.appendChild(fileLink);
           fileLink.click();
 
           //tempファイル削除
          // this.deletzip(response.data['temp'],response.data['name']);
         
       })
       .catch((e)=>{
         console.log(e);
       })
          
        
     



        //   this.$emit("downloading", 0);
        //   let params = new URLSearchParams();
        //     params.append('dl', "multi");
        //     params.append('ids', this.chk);
        //   this.axios.post(this.api + 'zip.php',params
        //   )
        //  .then((response)=>{
        //     console.log(response)
             
        //      tempファイル削除
        //      console.log(response.data)
        //     this.deletzip(response.data['temp'],response.data['name']);
        //     this.$emit("downloading", 0);
           
        //  })
        //  .catch((e)=>{
        //    console.log(e);
        //  })
       },
        deletzip(temp,name){
         const url = this.api + 'download.php'
          this.axios.get(url,{
             params: {
             type: "zipdelete",
             temp:temp,
             name:name,
         },
         })
         .then(()=>{
          this.$emit("downloading", 0);
         })
         .catch((e)=>{
           console.log(e);
         })
       }
    },
    watch:{
  
    
  }
}