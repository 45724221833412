<template>
<div>
  <div class="modal">
      <div class="modal-content">
           <div class="modal-close"><button @click="changeMordal()"><img src="@/assets/images/close.svg"></button></div>
                <h1>アーカイブ編集</h1>
                <table>
                  <tr>
                    <th>ID</th>
                    <td>{{formatId(id)}}</td>
                  </tr>
                   <tr>
                    <th>登録日</th>
                    <td>
                        <DatePicker
                        :popover="popover"
                        mode="dateTime" 
                        v-model="rday">
                        <template v-slot="{ inputValue, inputEvents }">
                          <input :value="inputValue"  v-on="inputEvents" />
                        </template>
                      </DatePicker>

                     
                      </td>
                  </tr>
                  <tr>
                    <th>年度</th>
                    <td>
                          <select class="select year" v-model="year">
                            <option v-for="y in years" :key="y" :value="formatYear(y)">{{y}}</option>
                          </select>
                      </td>
                  </tr>
                  <tr>
                    <th>タイトル</th>
                    <td><input type="text" v-model="title" class="input"></td>
                  </tr>
                  <tr>
                    <th>カテゴリ</th>
                    <td><select class="select category" v-model="cate">
                      <option v-for ="category in categories" :key="category.c_id" :value="category.c_id">{{category.c_name}}</option>
                    </select></td>
                  </tr>
                  <tr>
                    <th>キーワード</th>
                    <td><input type="text" v-model="keywords" class="input"></td>
                  </tr>
                  <tr>
                  <tr>
                    <th>コメント</th>
                    <td><input type="text" v-model="memo" class="input"></td>
                  </tr>
                  <tr>
                    <th>データ</th>
                    <td>
                      <input type="checkbox" value="1" v-model="chgdata">データを変更する({{d_name}})
                      <tamplate v-if="chgdata==1">
                        <div class="droparea" @dragenter="dragEnter" @dragleave="dragLeave"  @dragover.prevent  @drop.prevent="dropFile" :class="{enter: isEnter}">
                          <p>{{dropname}}</p>

                        </div>
                        <label  class="inputfile"><input type="file" accept="png, .jpg, .jpeg, .pdf, .doc, .pdf"  @change="dropFile">ファイルを選択</label >
                     </tamplate>
                    
                    </td>
                  </tr>
                </table>
               <p class="submit"><button class="btn btn-m blue" @click="updatedoc()">更新</button></p>

        </div>
    
         
  </div>

</div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from "vuex"
import commonData from '@/assets/js/commonData'
import year from '@/assets/js/year'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
export default {
  mixins: [commonData,year],
   props:[
          'id',
      ],
  components: {
   DatePicker
  },
   
  data(){
    return{
      tx:'NOW UPDATING',
      // years:[],
      categories:[],
      d_name:'',
      cate:'',
      memo:'',
      title:'',
      year:'',
       filedata:'',
      chgdata:0,
      isEnter: false,
      dropname:"ファイルをドロップしてください",
       files:[],
      st:0,
      keywords:'',
       rday:'',
       formats:{
           title: "YYYY MMMM ",
            weekdays: "W",
            navMonths: "MMM",
            dayPopover: "WWW, MMM D, YYYY",
            input: ["L", "YYYY-MM-DD", "YYYY/MM/DD"],
            data: ["L", "YYYY-MM-DD", "YYYY/MM/DD"]
        },
        inputValue: new Date(),
         popover: {visibility: 'click'}
    }
  },
  methods:{
     changeMordal(){
       
        this.$emit('mordal-off',0) ;
   },
    updatedoc(){
      this.st = 1;
     const regster_day = new Date(this.rday)
     const regster_year = regster_day .getFullYear()
     const regster_month = regster_day .getMonth()+1
     const regster_date = regster_day .getDate()
     const regster_h = regster_day .getHours()
     const regster_m = regster_day .getMinutes()
     const regster_s = regster_day .getSeconds()
     const rd = `${regster_year}-${regster_month}-${regster_date} `
     const rh = `${regster_h}:${regster_m}:${regster_s} `
     let data_change = 'false'
    if(this.chgdata == 0 ){
       data_change = 'false'; 
    }else{
       data_change = 'true'; 
    }

      let params = new FormData();
            params.append('admin', "supload")
            params.append('chgdata',data_change)
            params.append('id', this.id)
            params.append('year', this.year)
            params.append('title', this.title)
            params.append('cate', this.cate)
            params.append('memo', this.memo)
            params.append('file',this.files[0])
            params.append('keywords',this.keywords)
            params.append('rday',rd + rh)
           
        this.axios.post(this.api+"upload.php" ,params
      )
      .then((response)=>{
          //   console.log(response.data)
          if(response.data){
             this.st = 0;
             this.$emit('mordal-off',0) 
  
          }else{
             this.st = 1;
             alert('UPDATE ERROR');
          }
      })
      .catch((e)=>{
        console.log(e);
      })
       

    },
    dragEnter() {
        // console.log('Enter Drop Area');
        this.isEnter = true;
    },
    dragLeave(){
      this.isEnter = false;
    },
    dragOver() {
    // console.log('DragOver')

},
dropFile(e) {
    // console.log(event.dataTransfer.files)
    const d = e.target.files || e.dataTransfer.files
     this.files = d
     this.dropname = d[0].name
     
  //  console.log(this.files)
    this.isEnter = false;
},

    getCategory(){
        this.axios.get(this.api,{
          params: {
          g: "category",
      }

      })
      .then((response)=>{
        this.categories = response.data
        // console.log(response.data)
         
      })
      .catch((e)=>{
        console.log(e);
      })
    },
    getDocid(id){
       this.axios.get(this.api,{
          params: {
          g: "docdetail",
          id:id
      }

      })
      .then((response)=>{
      
         this.rday = response.data.register_at
        this.d_name = response.data.d_name
        this.cate = response.data.cate
        this.memo = response.data.memo
        this.title = response.data.title
        this.year = response.data.year
        this.keywords = response.data.keywords
        //  console.log(response.data)
         
      })
      .catch((e)=>{
        console.log(e);
      })

    }

  },
  watch:{
   
    
  },
  computed:{
    ...mapGetters([

       'categories'
      ]),
  },
  mounted(){
    this.yearArr()
    this.getCategory()
    this.getDocid(this.id)
  }

}
</script>
<style scoped lang="scss">
@import "@/scss/common.scss";
h1{
  color: rgb(0,0,0);
}
.input{
  width: 90%;
}
table{
  width: 100%;
}
th,td{
  padding: 10px 3px;
}
th{
  width: 80px;
}
tr{
  border-bottom: 1px solid $blue;
}
.submit{
  margin: 30px auto 0 auto;
  text-align: center;
  button{
    margin: 0 auto;
  }
}
.inputfile{
  margin: 15px 0;
}
.droparea{
  background-color: rgb(230,230,230);
  width: 100%;
  height: 80px;
  margin: 15px 0;
  border: dotted rgb(150,150,150) 3px;
  transition: .2s;
  p{
    text-align: center;
    padding: 30px 0;
  }
}
.enter {
    border:1px solid $blue;
    border-radius: 20px;
    background-color: rgb(125, 221, 250);
}
.inputfile{
  padding: 10px 40px;
    color: #ffffff;
    background-color: #384878;
    cursor: pointer;
}
input[type="file"] {
    display: none;
}
</style>



