export default {
    data(){
        return{
          years:[],
      }
    },
    methods:{
      formatYear(year){
        let y = year.split('-')
         y = String(y[0]) + String(y[1])
         return Number(y)
     },
     toyear(){
       const today = new Date()
      //  const firstday = new Date(today.getFullYear() + '-07-01');

       return (today.getFullYear() + 2) + '-' + ('00' + (today.getFullYear() - 2000)).slice(-2);

      //  if(today < firstday){
      //      return (today.getFullYear()+1) + '-' + ('00'+(today.getFullYear()-2000)).slice(-2);
      //  }else{
      //     return  (today.getFullYear()) + '-' + ('00'+(today.getFullYear()+1-2000)).slice(-2);
      //  }
     },
     yearArr(){
         const thisYear = this.toyear().split('-')
         let arr = []
         let i = 0
         let n =  Number(thisYear[0])
        let next
         const limit = 1948
         while(n > limit){
           next = String(n+1)
           next = next.slice(-2)
           arr[i] = n + '-' + next
           n--
           i++

         }
     this.years = arr
     },


    },
    watch:{


    },
    mounted(){
      this.yearArr()
      this.search.sYear = this.formatYear(this.toyear())
      this.search.eYear = this.formatYear(this.toyear())
    }
}
