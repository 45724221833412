<template>
  <div id="admin">
    <LoginChk></LoginChk>
    <Header></Header>
    <div class="content list-width">
       
             <h1>アーカイブ編集</h1>
             <div class="table">
               <div class="top-linkbtn">
                  <router-link to="/List" class="btn-m orange">一覧</router-link>
            </div>
                <table>
                  <tr>
                    <th>登録日</th>
                    <td>
                        <DatePicker
                        :popover="popover"
                        mode="dateTime" 
                        v-model="rday">
                        <template v-slot="{ inputValue, inputEvents }">
                          <input :value="inputValue"  v-on="inputEvents" />
                        </template>
                      </DatePicker>

                     
                      </td>
                  </tr>
                  <tr>
                    <th>年度</th>
                    <td>
                          <select class="select year" v-model="year">
                            <option v-for="y in years" :key="y" :value="formatYear(y)">{{y}}</option>
                          </select>
                      </td>
                  </tr>
                  <tr>
                    <th>タイトル</th>
                    <td><input type="text" v-model="title" class="input"></td>
                  </tr>
                  <tr>
                    <th>カテゴリ</th>
                    <td><select class="select category" v-model="cate">
                      <option v-for ="category in categories" :key="category.c_id" :value="category.c_id">{{category.c_name}}</option>
                    </select></td>
                  </tr>
                  <tr>
                    <th>キーワード</th>
                    <td><input type="text" v-model="keywords" class="input"></td>
                  </tr>
                  <tr>
                    <th>コメント</th>
                    <td><input type="text" v-model="memo" class="input"></td>
                  </tr>
                  
                  <tr>
                    <th>データ</th>
                    <td>
                        <div class="droparea" @dragenter="dragEnter" @dragleave="dragLeave"  @dragover.prevent  @drop.prevent="dropFile" :class="{enter: isEnter}">
                          <p>{{dropname}}</p>

                        </div>
                        <label  class="inputfile"><input type="file"  accept="png, .jpg, .jpeg, .pdf, .doc, .pdf"  @change="dropFile">ファイルを選択</label >
                    
                    </td>
                  </tr>
                </table>

               <p class="submit"><button class="btn btn-m blue" @click="insertDoc()">登録</button></p>
</div>
        </div>
        <template v-if="st==1">
             <Animation :tx="tx"></Animation>
        </template>
           
    
        </div>
    
     



    
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header'
import Animation from '@/components/Animation'
import LoginChk from '@/components/LoginChk'
import { mapGetters } from "vuex"
import commonData from '@/assets/js/commonData'
import year from '@/assets/js/year'
import category from '@/assets/js/category'

import DatePicker from 'v-calendar/lib/components/date-picker.umd'



export default {
  mixins: [commonData,year,category],
  name: 'Register',
  components: {
    Header,
    Animation,
    LoginChk,

    DatePicker
    
 
  
    
  },
  data(){
    return{
      dropname:"ファイルをドロップしてください",
      lists:[],
      dl:0,
      cate:'',
      st:0,
      year:0,
      title:'',
      rday:new Date(),
      keywords:'',
      memo:'',
       filedata:'',
        isEnter: false,
        files:[],
        tx:"NOW UPDATING",

        formats:{
           title: "YYYY MMMM ",
            weekdays: "W",
            navMonths: "MMM",
            dayPopover: "WWW, MMM D, YYYY",
            input: ["L", "YYYY-MM-DD", "YYYY/MM/DD"],
            data: ["L", "YYYY-MM-DD", "YYYY/MM/DD"]
        },
        inputValue: new Date(),
         popover: {visibility: 'click'}
      
    }
  },
  methods:{
    insertDoc(){
     this.st = 1
     const regster_day = new Date(this.rday)
     const regster_year = regster_day .getFullYear()
     const regster_month = regster_day .getMonth()+1
     const regster_date = regster_day .getDate()
     const regster_h = regster_day .getHours()
     const regster_m = regster_day .getMinutes()
     const regster_s = regster_day .getSeconds()
     const rd = `${regster_year}-${regster_month}-${regster_date} `
     const rh = `${regster_h}:${regster_m}:${regster_s} `


    //  console.log(regster_date);
      let params = new FormData();
            params.append('docinsert', "supload")
            params.append('year', this.year)
            params.append('title', this.title)
            params.append('cate', this.cate)
            params.append('memo', this.memo)
            params.append('file',this.files[0])
            params.append('keywords',this.keywords)
            params.append('rday',rd + rh)

        this.axios.post(this.api+"upload.php" ,params
      )
      .then((response)=>{
        // console.log(response.data)
        if(response){
          this.st = 0
          if(!confirm('続けて登録しますか？')){
              this.$router.push('/List')
          }else{
            this.title=""
            this.cate=""
            this.memo=""
            this.file=""
            this.dropname = "ファイルをドロップしてください"
          }
          
        }else{
          alert("登録エラー\nお手数ですが再登録をお願いします。");
        }
       
          
      })
      .catch((e)=>{
        console.log(e);
        
      })
       

    },
   dragEnter() {
        // console.log('Enter Drop Area');
        this.isEnter = true;
    },
    dragLeave(){
      this.isEnter = false;
    },
    dragOver() {
    // console.log('DragOver')
},
dropFile(e) {
    // console.log(event.dataTransfer.files)
    const d = e.target.files || e.dataTransfer.files
     this.files = d
     this.dropname = d[0].name
     
  //  console.log(this.files)
    this.isEnter = false;
},
    formatYear(year){
       let y = year.split('-')
        y = String(y[0]) + String(y[1])
        return Number(y)
    },

  },
   watch:{
     rday(value){
       console.log(value)
     }
  
    
  },
  computed:{
    ...mapGetters([
  
      ]),

     
  },
  mounted(){
    this.year = this.formatYear(this.years[0]);
  },


}
</script>
<style scoped lang="scss">
@import "@/scss/common.scss";
.table{
  position: relative;
  width: 500px;
  padding: 15px;
  background: rgb(255,255,255);
  margin: 15px auto;
}
.input{
  width: 90%;
}
table{
  width: 100%;
  position: relative;
}
th,td{
  padding: 10px 3px;
}
th{
  width: 80px;
}
tr{
  border-bottom: 1px solid $blue;
}
.submit{
  margin: 30px auto 0 auto;
  text-align: center;
  button{
    margin: 0 auto;
  }
}
.inputfile{
  margin: 15px 0;
}
.droparea{
  background-color: rgb(230,230,230);
  width: 100%;
  height: 80px;
  margin: 15px 0;
  border: dotted rgb(150,150,150) 3px;
  transition: .2s;
  p{
    text-align: center;
    padding: 30px 0;
  }
}
.enter {
    border:1px solid $blue;
    border-radius: 20px;
    background-color: rgb(125, 221, 250);
}
.inputfile{
  padding: 10px 40px;
    color: #ffffff;
    background-color: #384878;
    cursor: pointer;
}
input[type="file"] {
    display: none;
}
</style>



