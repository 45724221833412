<template>
  <div class="header">
    <div  class="header-container">
      <img src="@/assets/images/logo_head_left.svg" class="header-container-left">
      <img src="@/assets/images/logo_head_right.svg" class="header-container-right">
    </div>
  
  </div>
</template>

<script>
export default {
 
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/scss/common.scss";

.header{
  width: 100%;
  color:white;
 
  border-bottom: 1px $gray solid;
}
.header-container{
  max-width: $width;
  margin: 0 auto;
  display: flex;
  align-items: center;
   height: 78px;
  
  &-left{
    height: 54px;
  }
  &-right{
    margin-left: auto;
     height: 25px;
  }
}
</style>
