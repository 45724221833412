<template>
   <div id="admin">
   <LoginChk></LoginChk>
    <Header></Header>
     
    <Datalists 
      :usertype="usertype" 
      @downloading="dl=$event"
      @click-detail="detail($event)"
    >
    </Datalists>
   <template v-if="dl==1">
       <Animation :tx="tx"></Animation>
   </template>
  <template v-if="showudadatedisplay=='1'">

        <UpdateDoc :id=updateid
        @mordal-off="showudadatedisplay = $event"
        ></UpdateDoc>
        </template>

        
 
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header'
import Datalists from '@/components/Datalists'
import Animation from '@/components/Animation'
import UpdateDoc from '@/components/updateDoc'
import LoginChk from '@/components/LoginChk'
import { mapGetters } from "vuex"
import commonData from '@/assets/js/commonData'
export default {
  mixins: [commonData],
  name: 'Archives',
  components: {
   Datalists,
   Header,
   Animation,
   UpdateDoc,
   LoginChk
 
  },
  data(){
    return{
      usertype:"１",
      dl:0,
      tx:"NOW DOWNLOADING",
      update:0,
      showudadatedisplay:"0",
      updateid:"",
      rerendaring:0,
    }
  },
  methods:{
    detail(id){
    this.updateid = id
    this.showudadatedisplay = 1
    this.rerendaring = 0
    },
     firstDisplay(){
      const jsDysplay = document.getElementById('jsDysplay');
      jsDysplay.click();
    }
    

 
  },
   watch:{

  },
  computed:{
    ...mapGetters([
      //  'getSearch',
      //  'getPrimary',
      //  'search',
      //  'primary'
      ]),
      //  getLists(){
      //  let end = this.currentPage * this.pageRange;
      //  let start = (this.currentPage * this.pageRange)-this.pageRange;
      //  return this.lists.slice(start, end);

      // },
  },
  mounted(){
     this.firstDisplay();
  },


}
</script>
<style scoped lang="scss">
@import "@/scss/common.scss";
p{
  text-align: center;
  color: rgb(255,255,255);
  font-size: 30px;
}
</style>



